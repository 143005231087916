.teacher-container {
    height: 100vh;
    background-color: var(--c-teachers-background);
}

.teacher-description-container {
    padding-top: 5rem !important;
    width: 90vw;
    margin: auto !important;
}

.teacher-description {
    font-size: var(--d-font-home-programs-description);
    font-weight: var(--s-font-home-programs-description);
    position: relative;
}

.img-teacher-style {
    display: inline-block;
    border-radius: 15px;
    height: 25vh;
    width: 100%;
    object-fit: cover;
    margin: auto;
}

.img-slider-style {
    display: inline-block;
    border-radius: 15px;
    width: 100%;
    margin: auto;
}

.r180 {
    rotate: 180deg;
}

.img-teacher-style-selected {
    display: inline-block;
    border-radius: 15px;
    width: 100%;
    margin: auto;
    object-fit: cover;
    object-position: 50%;
    height: 80vh;
}

.icon-style {
    width: 32px;
    display: block;
    margin: auto;
    float: left;
}

.teacher-description-header {
    font-size: var(--d-font-home-teachers-header);
    font-weight: var(--d-font-home-teachers-description);
    margin-top: 10%;
    transition: all 0.6s ease-in-out;
}

.teacher-text {
    margin: auto;
    text-align: justify;
}

.column {
    float: left;
    width: 15% !important;
    padding: 5px !important;
    align-content: end;
}

.column-selected {
    width: 40% !important;
    padding: 5px !important;
}

.row::after {
    content: "";
    clear: both;
    display: table;
}

.slider {
    float: left;
    width: 55% !important;
    padding: 5px;
    align-content: center;
    background-color: var(--c-slider-background);
    border-radius: 25px;
    height: 25vh;
    object-fit: cover;
}

.slider-column {
    width: 7.5% !important;
    padding: 5px !important;
    align-content: end;
    margin-left: auto;
    margin-right: auto;
}