.m-b-8 {
    padding-top: 15% !important;
    padding-bottom: 1rem !important;
}

.m-l-2{
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}

.top-bar{
    font-size: var(--d-font-home-programs-header);  
    align-items: center; 
}

.active{
    font-weight: var(--s-font-home-programs-header);
    text-decoration: underline;
    text-underline-offset: 8px;
}

.horizontalList{
    list-style: none;
    align-items: right;
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.main-programs-container{
    height: 100vh;
    background-color: var(--c-programs-background);
}

.click-mouse{
    cursor:pointer;
    text-transform: uppercase;
}

.program-description-container {
    margin-top: 4rem !important;
    height: 50vh;
}

.program-description {
    font-size: var(--d-font-home-programs-description);
    font-weight: var(--s-font-home-programs-description);
    padding: 0 10% 0 5%;
    position: relative;
    /* padding: 4rem 10rem; */
    text-align: justify;
    width: 75%;
}

.button-position {
    position: absolute;
    margin: 0 10%;
    top: 70%;
    width: 30rem;
    min-width: fit-content;
}

.pr {
    position: relative;
}

.videoPreview {
    margin-left: auto;
    margin-right: auto;
    display: block;
}