@import url("../../Common/c_colors.css");
@import url("../../Common/c_dimmentions.css");
@import url("../../Common/c_fonts.css");

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

ul {
  list-style: none;
  margin-top:unset !important;
  margin-bottom: unset !important;
}

/* Media rules */

/* it will use the following rule if the width is less than 500px. */
@media (max-width: 500px) {
  
  /* hamburger */
  .hamburger {
    display: block;
    cursor: pointer;
  }

  .bar {
    background-color: #120f0b;
    width: 20px;
    height: 3px;
    display: block;
    margin: 5px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }

  /* adding active class to the bars  */
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .navbar-menu{
    position: absolute;
      flex-direction: column;
      gap: 0;
      top: 70px;
      left: -100%;
      text-align: start;
      width: 100%;
      transition: 0.7s ease-in-out;
      /* background-color: rgb(202, 206, 209); */
      padding: 10px;
    }
  
    .navbar-menu.active{
      left: 0;
    }
  
    .navbar-menu li {
      margin: 16px 0;
    }
}

/*navbar*/
.navbar-style {
  position: fixed !important;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
  height: var(--top-navbar-height, 3rem);
  color: var(--c-navbar-foreground, #fff);
  z-index: 10;
  transition: 0.4s;
}

.navbar-style:hover{
  background-color: var(--c-navbar-background, #ffffff80) !important;
}

/*navMenu*/
.navbar-menu {
  /* --background-color: var(--c-navbar-background, #ffffff80) !important; */
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  transition: 0.3s;
  width: 100%!important;
}

/*navLink*/
.navbar-link-custom {
  font-size: var(--d-font-navbar-items);
  font-weight: var(--s-font-navbar-items);
  color: var(--c-navbar-foreground, #fff) !important;
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.navbar-brand-style {
  font-size: var(--d-font-navbar-brand) !important;
  font-weight: var(--s-font-navbar-brand) !important;
  color: var(--c-navbar-branding-foreground, #fff) !important;
  font-weight: bolder;
}

.navbar-logotype{
  width: 3vh;
}