@import url("../../../Common/c_fonts.css");
@import url("../../../Common/c_colors.css");
@import url("../../../Common/c_dimmentions.css");
@import url("../../../Common/c_animations.css");

.program-container1 {
    display: flex;
    flex-flow: row wrap;
    height: 45vh;
    margin: 5vh 2vw;
}

/* We tell all items to be 100% width, via flex-basis */
.program-container1>* {
    flex: 1 100%;
}

/* Medium screens */
@media all and (min-width: 600px) {

    /* We tell both sidebars to share a row */
    .aside {
        flex: 1 auto;
    }
}

/* Large screens */
@media all and (min-width: 800px) {

    /* We invert order of first sidebar and main
     * And tell the main element to take twice as much width as the other two sidebars 
     */
    .main {
        flex: 3 0px;
        order: 2;
        padding: 1rem;
    }

    .aside-1 {
        order: 1;
    }

}

.header {
    text-align: left;
    max-height: 8vh;
}

.video-player {
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
}

.ptitle {
    font-weight: lighter;
    text-transform: uppercase;
    font-size: large;
}

.ptags {
    text-transform: uppercase;
    font-size: small;
    font-weight: lighter;
}

.btn-empezar {
    background-color: var(--c-buttons-background);
    color: var(--c-buttons-foreground);
    font-size: 15px;
    font-weight: var(--s-font-buttons);
    border-radius: 5rem;
    padding: 0.5rem 5rem;
    min-width: 20vh;
    border: none;
    width: 25vw;
}

.img_thumbnails_carrousel_slider {
    height: 100% !important;
    padding: 5px !important;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.img_thumbnails_carrousel {
    width: 18vw;
    height: 45%;
    border-radius: 10px;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
    align-content: end;
    scroll-snap-align: none center;
    overflow: clip;
}

.img_thumbnail_2{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    height: 100%;

}

.img_thumbnail {
    width: 100%;
    height: 100%;
    min-height: 100%;
    background-color: rgb(240 240 240 / 80%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: inline-table;
    object-fit: cover;
    background-size: cover;
}

.slider-sm {
    float: left;
    padding: 5px;
    align-content: center;
    background-color: var(--c-slider-background);
    border-radius: 25px;
    height: 100%;
    object-fit: cover;
}

.img-videothumbnail {
    height: 10vh;
    object-fit: cover;
    align-content: center;
}

.thumbnails_container {
    width: 85%;
    height: 100%;
    margin: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    scroll-snap-type: x mandatory;
    align-content: center;
    scrollbar-width: none;
    scroll-behavior: smooth;
}