.contact-title {
    font-size: var(--d-font-contact-title);
    font-weight: var(--s-font-contact-title);
    margin-bottom: 2vh;
    margin-left: 15%;
}

.contact-icons {
    margin-left: 15%;
    list-style: none;
    align-items: right;
    display: flex;
    justify-content: start;
    gap: 25px;
}

.subscribe-form {
    margin-left: 10%;
    margin-top: 4%;
    padding-bottom: 1vh;
    display: block;
    text-align: center;
}

.email-control {
    width: 500px !important;
    font-size: var(--d-font-contact-content);
    font-weight: var(--s-font-contact-content);
    background-color: var(--c-contact-email-background);
    line-height: 1.5;
    border-radius: 20px;
    margin-left: 2rem;
}

.contact-container {
    height: 100vh;
    background-color: var(--c-contacts-background);
    padding-top: 25vh;
}