@import url("../Common/c_colors.css");
@import url("../Common/c_dimmentions.css");
@import url("../Common/c_fonts.css");
@import url("../Common/c_animations.css");

.background-player {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.video-container {
    position: relative;
    width: 100%;
    height: 100vh;
}

.video-foreground-content {
    position: absolute !important;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: var(--c-video-content-backgroung) !important;
    color: var(--c-video-content-foreground) !important;
    transition-property: all;
    transition-duration: var(--t-fade-in-duration);
    transition-delay: var(--t-fade-in-delay);
    opacity: 1;
}

.video-content-title {
    font-size: var(--d-font-video-content-title);
    font-weight: var(--s-font-weight-video-content-title);
    padding-left: 1rem;
}

.video-content-body {
    font-size: var(--d-font-video-content-body);
    font-weight: var(--s-font-weight-video-content-body);
    padding-left: 2rem;
    position: relative;
    top: 45%;
    color: var(--c-video-content-foreground);
}

.video-content-footer {
    font-size: var(--d-font-video-content-footer);
    font-weight: var(--s-font-weight-video-content-footer);
    position: relative;
    left: calc(50% - 216px);
    top: 60%;
    width: fit-content;
}

ul {
    list-style: none;
}

.translucent-foreground {
    background-color: #00000012;
    width: 100%;
    height: 100%;
    position: absolute !important;
    bottom: 0;
    left: 0;
}

.m-10{
    margin: 10rem;
}

.btn-general{
    background-color: var(--c-buttons-background);
    color: var(--c-buttons-foreground);
    font-size: var(--d-font-buttons);
    font-weight: var(--s-font-buttons);
    border-radius: 5rem;
    padding: 1rem 6rem;
    border: none;
    width: 400px;
}