.plans-main-container {
    padding-top: 25vh;
    text-align: center;
    text-wrap: balance;
    height: 100vh;
    background-color: var(--c-plans-background);
}

.plans-title {
    font-size: var(--d-font-plans-title);
    font-weight: var(--s-font-plans-title);
    text-align: center;
}

.plans-content {
    font-size: var(--d-font-plans-content);
    font-weight: var(--s-font-plans-content);
    margin-top: 4% !important;
    width: 80vh;
    display: inline-block;
}

.mg-10 {
    margin-top: 10% !important;
}

.plans-btns {
    width: 80vh;
    position: relative;
    align-self: center;
}

.table-center {
    margin-left: auto;
    margin-right: auto;
    padding-top: 10vh;
    margin-top: 5vh;
}