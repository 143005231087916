.review-container2 {
    height: 100vh;
    background-color: var(--c-reviews-background);
}

.main-slider-container2 {
    margin: auto !important;
    position: relative;
    display: flex;
    align-items: center;
    width: 95%;
    height: 100%;
}

.slider-container2 {
    width: 85%;
    height: 100%;
    margin: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    scroll-snap-type: x mandatory;
    align-content: center;
    scrollbar-width: none;
    scroll-behavior: smooth;
}

.slider-card2{
    width: 18vw;
    height: 45%;
    border-radius: 10px;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
    align-content: end;
    scroll-snap-align: none center;
    overflow: clip;
}

.review-container {
    height: 100vh;
    background-color: var(--c-reviews-background);
}


#slider::-webkit-scrollbar{
    display: none;
}

.main-slider-container {
    margin: auto !important;
    position: relative;
    display: flex;
    align-items: center;
    width: 90%;
    height: 80%;
}

.slider-icon {
    background: var(--br-c2-a2);
    color: black;
    border-radius: 100%;
    position: absolute;
    opacity: 0.5;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
    width: 50px;
    height: 50px;
}

.slider-icon.left {
    left: 0;
}

.slider-icon.right {
    right: 0;
    transform: rotateZ(180deg);
    transition: all 0.8s ease-out;
}

.slider-icon.left:hover, .slider-icon.right:hover{
    opacity: 1;
}

.slider-container {
    width: 80%;
    height: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-behavior: smooth;
    align-content: center;
    margin: auto;
}

.slider-card{
    width: 18vw;
    height: 45%;
    border-radius: 10px;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
    align-content: end;
}

.slider-card-content{
    opacity: 0;
    height: 100%;
    width: 80%;
    text-align: justify;
    margin-top: 15%;
    transition: all ease-in-out 0.5s;
    position:relative;
    top:0;
    left:0;
    background: var(--br-c2-a2);
    z-index: 5;
    display:block;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    text-wrap: wrap;
}

.card-content-quote-description{
    margin: 10px 40px 10px 40px;
    font-size: var(--d-font-carousel-description);
    font-weight: var(--s-font-carousel-description);
}

.card-content-quote{
    width: 50px;
    height: 50px;
    transform: rotateZ(180deg);
    margin: 20px 10px 20px 20px;
}

.card-content-face{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin: 20px 10px 20px 20px;
}

.card-content-name{
    font-size: var(--d-font-carousel-name);
    font-weight: var(--s-font-carousel-name);
}

.slider-card-focused{
    height: 70vh;
    width: 35vw;
    box-shadow: 6px 6px 6px 6px rgb(0 0 0 / 50%);
}

.slider-card-title, .slider-card-description{
    margin: 5px 0px 5px 10px;
}

.slider-card-title{
    font-weight: 500;
    margin-top: 10px;
}

.slider-card-description{
    opacity: 0.5;
    font-size: 13px;  
}

.slider-card-image{
    width: 100%;
    height: 100%;
    background-color: rgb(240 240 240 / 80%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: inline-table;
    object-fit: cover;
}