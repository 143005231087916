:root {
    /* Branding */
    --generic-white: white;
    --br-c1: #625b48;
    --br-c2: #9b9489;
    --br-c3: #e4e2d9;
    --br-c4: #6f5238;
    --br-c5: #2f2e2d;
    --br-c1-a2: #625b4850;
    --br-c2-a2: #9b948950;
    --br-c3-a2: #e4e2d950;
    --br-c4-a2: #6f523850;
    --br-c5-a2: #2f2e2d50;

    --common-color-background: #fff;
    --common-color-foreground: #def;

    /** HOME */
    --c-main-background-g1:var(--br-c3);
    --c-main-background-g2:var(--br-c5-a2);

    --c-contact-email-background:var(--br-c3-a2);

    /* Nav Bar */
    --c-navbar-background: var(--br-c2-a2);
    --c-navbar-foreground: var(--br-c5);
    --c-navbar-branding-foreground: var(--br-c5);

    /* Home - Video Player content*/
    --c-video-content-backgroung: #0000009c;
    --c-video-content-foreground: #E4E2D9;

    /* Buttons */
    --c-buttons-background: var(--br-c2-a2);
    --c-buttons-foreground: var(--branding-c3);

    /* Programs */
    --c-programs-background:var(--br-c3);

    /* Teachers */
    --c-teachers-background:var(--br-c2);
    --c-slider-background:var(--br-c3-a2);

    /* Reviews*/
    --c-reviews-background:var(--br-c1);
    
    /* Plans */
    --c-plans-background:var(--br-c3);

    /* Contacts */
    --c-contacts-background:var(--br-c2);

    /** PROGRAMS */

    /* Program */
    --c-programs-main-background:var(--generic-white);
}