@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

:root {

    /* Common*/

    /* * Button */
    /* size - dimentions */
    --d-font-buttons: calc(12px + 1vmin);
    --s-font-buttons: 800;

    /* * Nav Bar*/
    /* size - dimentions */
    --d-font-navbar-items: calc(10px + 1vmin);
    --d-font-navbar-brand: calc(14px + 1vmin);

    /* weight - style */
    --s-font-navbar-items: 400;
    --s-font-navbar-brand: 800;

    /* * Home - Plans */
    /* size - dimentions */
    --d-font-contact-title: calc(40px + 2vmin);
    --d-font-contact-content: calc(10px + 1vmin);

    /* weight - style */
    --s-font-contact-title: 800;
    --s-font-contact-content: 400;

    /* * Home - Plans */
    /* size - dimentions */
    --d-font-plans-title: calc(40px + 2vmin);
    --d-font-plans-content: calc(12px + 1vmin);

    /* weight - style */
    --s-font-plans-title: 800;
    --s-font-plans-content: 400;

    /* * Home - Teachers */
    /* size - dimentions */
    --d-font-home-teachers-header: calc(40px + 2vmin);
    --d-font-home-teachers-description: calc(12px + 1vmin);

    /* weight - style */
    --s-font-home-teachers-header: 800;
    --s-font-home-teachers-description: 400;

    /* * Home - Programs */
    /* size - dimentions */
    --d-font-home-programs-header: calc(14px + 1vmin);
    --d-font-home-programs-description: calc(12px + 1vmin);

    /* weight - style */
    --s-font-home-programs-header: 800;
    --s-font-home-programs-description: 400;
    
    /* * Home - Carousel */
    /* size - dimentions */
    --d-font-carousel-description: calc(10px + 1vmin);
    --d-font-carousel-name: calc(5px + 1vmin);

    /* weight - style */
    --s-font-carousel-name: 800;
    --s-font-carousel-description: 400;

    /* * Home - Video Player content*/
    /* size - dimentions */
    --d-font-video-content-title: 24px;
    --d-font-video-content-body: calc(10px + 3vmin);
    --d-font-video-content-footer: 12px;

    /* weight - style */
    --s-font-weight-video-content-title: 400;
    --s-font-weight-video-content-body: 400;
    --s-font-weight-video-content-footer: 400;

    /* Adding custom fonts */
    @font-face {
        font-family: 'Mafisha';
        src: url('./Fonts/Mafisha/Mafisha.otf') format('opentype');
    }

    @font-face {
        font-family: 'PlayFair';
        src: url('./Fonts/PlayFair_Display/PlayfairDisplay-VariableFont_wght.ttf') format('truetype');
    }

    @font-face {
        font-family: 'JosefinSans';
        src: url('./Fonts/Josefin_Sans/JosefinSans-VariableFont_wght.ttf') format('truetype');
    }

    @font-face {
        font-family: 'JosefinSans_Italic';
        src: url('./Fonts/Josefin_Sans/JosefinSans-Italic-VariableFont_wght.ttf') format('truetype');
    }

    @font-face {
        font-family: 'JosefinSans_Light';
        src: url('./Fonts/Josefin_Sans/JosefinSans-Light.ttf') format('truetype');
    }
}

.font-mafisha {
    font-family: 'Mafisha' !important;
}

.font-josefin {
    font-family: 'Josefin Sans' !important;
}

.font-josefin-italic {
    font-family: 'JosefinSans_Light' !important;
    font-style: italic;
}

.font-josefin-light {

    font-family: 'JosefinSans' !important;
}

.font-playfair {
    font-family: 'PlayFair' !important;
}
