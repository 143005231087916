:root {

    /* Common*/
    --common-label-width: 8rem;

    /* Nav Bar*/
    --top-navbar-height: 3rem;

    /* Home - Video Player content*/
    /** Position **/
    --video-content-bottom-space: 2%;

}

.h_vh_35 {
    height: 35vh;
}

.pl_10_px {
    padding-left: 10px !important;
}

.pr_10_px {
    padding-right: 10px !important;
}

.pt_5_px{
    padding-top: 5px;
}

.pt_10_px{
    padding-top: 10px;
}

.pt_30_px{
    padding-top: 30px;
}

.pb_5_px{
    padding-bottom: 5px;
}

.pb_10_px{
    padding-bottom: 10px;
}

.pb_30_px{
    padding-bottom: 30px;
}

.ml_10_px {
    margin-left: 10px !important;
}

.mr_10_px {
    margin-right: 10px !important;
}

.w_M_100 {
    width: 100% !important;
    max-width: 100% !important;
}

.h_40{
height: 40%;
}

.h_50{
height: 50%;
}

.h_10{
height: 10%
}